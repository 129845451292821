import { AssetManagementTenancyNullOrder, type InputMaybe, type Scalars } from "~/graphql/generated/graphql";

export type QueryPagination = {
  page: number;
  pageSize: number;
};

/**NOTE Sven: We default to strict for help with DESC / ASC, but when it comes from the backend it's just a string type
 * so in some cases we want to allow that
 */
export type QuerySort = {
  key: string;
  direction?: ("DESC" | "ASC") | null;
  relation?: string | null;
  overrideNullOrder?: AssetManagementTenancyNullOrder;
};

export type QuerySortLoose = Omit<QuerySort, "direction"> & {
  direction?: string | null;
};

export type QueryFilter = {
  matches: Array<Scalars["Map"]["input"]>;
  or?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryFilterCollection = {
  groups: Array<QueryFilter>;
};

export type QueryMetadata = {
  pageSize: number;
  totalCount: number;
};

export type AssetManagementQueryVariables = {
  sort?: QuerySort;
  pagination?: QueryPagination;
  filters?: QueryFilterCollection;
};

export enum Currency {
  DKK = "DKK",
  USD = "USD",
}
